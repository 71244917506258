<template>
  <div class="address-page">
    <van-form @submit="onSubmit">
      <van-field
        v-model="contact"
        name="contact"
        :label="PAGELANG.收货人"
        :placeholder="PAGELANG.请输入收货人姓名"
        :rules="[{ required: true, message: PAGELANG.收货人姓名不能为空 }]"
      />
	  <van-field
	    v-model="curPerfix"
	    is-link
	    readonly
		label="手機區號"
	    @click="showPrefix = true"
	  />
	  
	  <van-popup v-model:show="showPrefix" round position="bottom">
	    <van-picker
	      :columns="mobileperfix"
	      v-model="selectedlang"
	      @cancel="showPrefix = false"
	      @confirm="onConfirmPhone"
	      :title="PAGELANG.请选择地区"
	  	:columns-field-names="{ text: 'countryName' ,value:'countryNum' }"
	  	defaultIndex=2
	    />
	  </van-popup>
	  
      <van-field
        v-model="phone"
        name="phone"
        :label="PAGELANG.手机号码"
        placeholder="請輸入手機號(無需加區號)"
        :rules="[{ required: true, message: PAGELANG.手机号码不能为空 }]"
      />

 <!--     <van-field
        v-model="Country"
        is-link
        readonly
        name="picker"
        :label="PAGELANG.国家"
        :placeholder="PAGELANG.选择国家"
        @click="showCountry = true"
        :rules="[{ required: true, message: PAGELANG.请选择国家 }]"
      />
      <van-popup v-model:show="showCountry" position="bottom">
        <van-picker
          name="CountryID"
          :title="PAGELANG.选择国家"
          :columns="countryList"
          :columns-field-names="addressFieldName"
          :loading="countryLoading"
          v-model="CountryID"
          @confirm="onConfirmCountry"
          @cancel="showCountry = false"
        />
      </van-popup> -->

      <van-field
        v-model="Area"
        is-link
        readonly
        name="picker"
        :label="PAGELANG.area"
        :placeholder="PAGELANG.选择省市区"
        @click="checkCountry"
        :rules="[{ required: true, message: PAGELANG.请选择所在区域 }]"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <van-cascader
          name="AreaIDs"
          :title="PAGELANG.area"
          :options="addressList"
          :field-names="addressFieldName"
          :loading="addressLoading"
          v-model="AreaID"
          @finish="onConfirm"
          @close="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="detail"
        name="detail"
        rows="4"
        :label="PAGELANG.详细地址"
        type="textarea"
        :placeholder="PAGELANG.小区楼栋门牌村等"
        :rules="[{ required: true, message: PAGELANG.详细地址不能为空 }]"
      />
      <van-field
        name="isDefault"
        :label="PAGELANG.设置为默认地址"
        label-width="8em"
        input-align="right"
      >
        <template #input>
          <van-switch
            v-model="isDefault"
            size="20"
            active-color="#66dca0"
            active-value="1"
            inactive-value="0"
          />
        </template>
      </van-field>

      <div class="add-btn-block">
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          >{{ SYSLANG.save }}</van-button
        >
      </div>
    </van-form>

    <van-button
      v-if="addrid > 0"
      plain
      square
      block
      type="primary"
      class="btn-delete"
      @click="onDelete"
      >{{ SYSLANG.delete }}</van-button
    >
  </div>
</template>

<script>
import {
  showConfirmDialog,
  showToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      addrid: 0,
      addressList: [],
      addressLoading: true,
      addressFieldName: {
        text: "name",
        value: "id",
        children: "children",
      },
      contact: "",
      phone: "",
      showArea: false,
      Area: "",
      AreaID:0,
      AreaIDs: [],
      detail: "",
      isDefault: false,
      showCountry: false,
      Country: "",
      CountryID: [],
      oleCountryID: 0,
      countryList: [],
      countryLoading: true,
	  curPerfix: "中國台灣",
	  areaPhone:"(886)",
	  showPrefix: false,
	  mobileperfix: [],
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.area_prefix).then((response) => {
	  console.log(response, this.langcode);
	  this.mobileperfix = response.data.data;
	  let langitem = this.mobileperfix.find((item) => {
	    if (item.value == this.langcode) return true;
	    return false;
	  });
	
	  if (langitem) {
	    this.selectedlang = [langitem.value];
	    this.curPerfix = langitem.text;
	  }
	
	  // if(langitem)
	  //   this.selectedlang =
	  // // this.curPerfix = response.data[0].text;
	});

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.address;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.countryLoading = true;
    this.axios.get(this.actions.addressAll).then(({ data }) => {
      closeToast();
      let { code} = data;
      console.log("code", code);
      if (code == "200") {
        this.countryList =[{id: "1", name: "中国"}];
		data.data.map((item,index)=>{
			item.value=index
		})
		this.addressList = data.data;
		console.log('this.addressList',this.addressList)
      }
      this.countryLoading = false;
    });

    if (this.$route.query.addrid) {
      this.addrid = this.$route.query.addrid;
      if (this.addrid.length> 0) {
        this.getAddress();
      }
    }
  },
  methods: {
	  
	onConfirmPhone({ selectedOptions }) {
	  console.log('selectedOptions',selectedOptions);
	  this.showPrefix = false;
	  this.curPerfix = selectedOptions[0].countryName+selectedOptions[0].countryNum;
	  this.areaPhone=selectedOptions[0].countryNum;
	  //setCookie("lh_aog_langcode", selectedOptions[0].value);
	  //location.reload();
	},  
    getAddress() {
     this.axios
        .get(this.actions.addressUserList,{params:{page:1,pageSize:15}})
        .then((res) => {
         let { code, message} = res.data;
		 console.log()
		 if (code == 200) {
			            console.log(123123213)
						let detail={}
						for (let i = 0; i < res.data.data.list.length; i++) {
						  if (res.data.data.list[i].addressId == this.addrid) {
								detail= res.data.data.list[i];
								break;
						  }
						}
						console.log('datail',detail)
		 				this.contact = detail.contact;
		 				this.phone = detail.phone;
		 				this.Country = detail.Country;
		 				this.CountryID[0] = detail.fkAreaIDL0;
		 				this.Area = detail.Area;
		 				//this.AreaIDs = detail.AreaIDs;
		 				// this.AreaID = detail.AreaIDs[detail.AreaIDs.length - 1];
		 				this.detail = detail.detail;
		 				this.isDefault = detail.isDefault;
		 				//this.getArea();
		 } else {
		   showToast({
		     message: this.SYSLANG[message],
		     forbidClick: true,
		     onClose: () => {
		       if (code == "NOTLOGIN") {
		         this.$router.replace({
		           path: "/login",
		         });
		       }
		     },
		   });
		 }
        })
    },
    onConfirmCountry(val){
      console.log(val);
      let Country = "";
      for (let i = 0; i < val.selectedOptions.length; i++) {
        if (Country != "" && val.selectedOptions[i].name) {
          Country += ",";
        }
        if (val.selectedOptions[i].name) {
          Country += val.selectedOptions[i].name;
        }
      }
      this.Country = Country;
      this.showCountry = false;

      console.log(this.oleCountryID, val.selectedValues[0]);
      if (this.oleCountryID != val.selectedValues[0]) {
        this.AreaIDs = [];
        this.Area = "";

        this.CountryID = val.selectedValues;
        this.oleCountryID = val.selectedValues[0];
        this.getArea();
      }
    },
    checkCountry() {
      // if (this.CountryID.length <= 0) {
      //   showToast(this.PAGELANG.请选择国家);
      //   return;
      // }
      this.showArea = true;
    },
    getArea() {
      this.addressLoading = true;
      this.axios
        .get(this.actions.address_area + this.CountryID[0])
        .then(({ data }) => {
          closeToast();
          let { code, msg, list } = data;
          console.log("address_area", msg);
          if (code == "SUCCESS") {
            this.addressList = list;
          }

          this.addressLoading = false;
        })
        .catch((error) => {
          console.log("views.detail.getArea.error", error);
          closeToast();
          this.getArea();
        });
    },
    onConfirm({ selectedOptions }) {
      let Area = "";
      this.AreaIDs = [];
      for (let i = 0; i < selectedOptions.length; i++) {
        if (Area != "" && selectedOptions[i].name) {
          Area += ",";
        }
        if (selectedOptions[i].name) {
          this.AreaIDs.push(selectedOptions[i].id);
          Area += selectedOptions[i].name;
        }
      }
      this.Area = Area;
      this.showArea = false;
      console.log("AreaID", this.AreaID);
    },
    onChange(val) {
      console.log("change", val);
    },
    onSubmit(postdata) {
      postdata.addrid = this.addrid;
      //postdata.AreaIDs = JSON.stringify(this.AreaIDs);
      postdata.CountryID = this.CountryID[0];
	  console.log('this.AreaIDs',this.AreaIDs)
	  postdata.provinceId=this.AreaIDs[0];
	  postdata.cityId=this.AreaIDs[1];
	  postdata.districtId=this.AreaIDs[2];
	  postdata.phone=postdata.phone+this.areaPhone
	  if(postdata.isDefault==false){postdata.isDefault=0}else{postdata.isDefault=1}
      showLoadingToast({
        message: this.SYSLANG.正在保存,
        duration: 0,
        forbidClick: true,
      });
      console.log("submit", postdata);
      if(this.addrid.length> 0){
		  postdata.addressId=this.addrid
		  this.axios
		    .post(this.actions.editressUserAdd, postdata).then((res)=>{
		  			  console.log('res',res);
		  			  if(res.data.code==200){
		  				showToast({
		  				  message:res.data.message,
		  				  forbidClick: true,
		  				  onClose: () => {
		  					 this.$router.back();
		  				  },
		  				})  
		  			  }else{
		  				showToast({
		  				  message:res.data.message,
		  				  forbidClick: true,
		  				})    
		  			  }
		  		})
      }else{
		this.axios
		  .post(this.actions.addressUserAdd, postdata).then((res)=>{
					  console.log('res',res);
					  if(res.data.code==200){
						showToast({
						  message:res.data.message,
						  forbidClick: true,
						  onClose: () => {
							 this.$router.back();
						  },
						})  
					  }else{
						showToast({
						  message:res.data.message,
						  forbidClick: true,
						})    
					  }
				})  
	  }
    },
    onDelete() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteaddrss,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.address_delete + this.addrid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.$router.back();
                  }
                },
              });
            })
            .catch((error) => {
              console.log("views.detail.onDelete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style scoped>
.address-page {
  padding: 16px 0 var(--van-submit-bar-height) 0;
  --van-cell-vertical-padding: 14px;
}

.address-page >>> .van-cell::after {
  left: calc(var(--van-padding-md) + var(--van-field-label-width));
}

.btn-delete {
  --van-button-radius: 0;
  --van-button-primary-border-color: #fff;
  --van-button-primary-background: #fe295f;
  --van-button-default-height: var(--van-submit-bar-button-height);
  --van-button-normal-font-size: var(--van-submit-bar-font-size);
  margin-top: 16px;
}
</style>









